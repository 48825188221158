<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="600"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text
                v-if="loading"
            >

                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{$t("Загрузка...")}}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>

            <v-card-text
                v-else-if="executerGroup"
                class="wrapperFormModal"
            >
                <v-card 
                    flat
                >
                    <v-card-text>

                        <!--поле В именительном падеже-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t("В_именительном_падеже")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-text-field
                                    :value="executerGroup.Name"
                                    @input="update({ property: 'Name', value: $event })"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <!--поле В дательном падеже-->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("В_дательном_падеже") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-text-field
                                    :value="executerGroup.NameDative"
                                    @input="update({ property: 'NameDative', value: $event })"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Исполнители") }}</label>
                            </v-col>
                            <v-col  cols="12" sm="12" md="8">
                                <div class="icon-click-row-group">
                                    <div @click="onExecutersSelect">
                                        <v-icon small>
                                            fas fa-edit
                                        </v-icon>
                                    </div>
                                    <div class="onlyReadData more-per-lab-wrap">

                                        <template v-for="executer in executerGroup.WebExecuterDataList.Data">

                                            <v-enterprise-chip
                                                v-if="executer[4] === executer[0]"
                                                :key="executer[1] + executer[0]"
                                                :name="executer[1]"
                                            />

                                            <v-workplace-chip
                                                v-else
                                                :key="executer[1] + executer[0]"
                                                :id="executer[0]"
                                                :name="executer[1]"
                                            />

                                        </template>

                                    </div>
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-text
                v-else
                class="wrapperFormModal"
            >
                <v-card
                    flat
                >
                    <v-card-text>
                        Произошла ошибка загрузки группы исполнителей
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="!loading || executerGroup"
                >
                    {{ $t("Сохранить") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "ExecutersGroupDlg",
    data () {
        return {
            title: "Группа_исполнителей",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            }
        }
    },
    computed: {
        ...mapGetters('dialogs/executersGroup', { executerGroup: 'getExecuterGroup', visible: 'isVisible', loading: 'isLoading', entid: 'getEnterpriseId' }),
    },
    methods: {
        ...mapActions('dialogs/executersGroup', { ok: 'ok', cancel: 'cancel', update: 'updateDataSource' }),
        async onExecutersSelect() {
            let selectMemberParams =  {
                title: "Выбор_исполнителей",
                entid: this.entid,
                includeInner: true,
                includeOuter: true,
                includeOuterEnterprises: true,
                multiple: true,
                selected: this.executerGroup.Executers.map(x => x.ExecuterID == '00000000-0000-0000-0000-000000000000' ? x.EnterpriseID : x.ExecuterID)
            }
            try {
                this.$store.commit('dialogs/executersGroup/SET_VISIBLE', false );
                let executers = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);

                let dataList = executers.reduce( function (list, executer) {

                    if(executer.employeeId)
                        list.push([ executer.workplaceId, `${executer.name}`, "сотрудники", true, executer.enterprise ]);
                    else
                        list.push([ executer.enterprise, `${executer.name}`, "организации", true, executer.enterprise ]);

                    return list;
                }, []);

                this.update({ property: 'EMSExecutersSelectId', value: '00000000-0000-0000-0000-000000000000' });
                this.update({ property: 'WebExecuterDataList.Data', value: dataList  });
            }
            catch (ex) {
                console.log(ex);
            }
            finally {
                this.$store.commit('dialogs/executersGroup/SET_VISIBLE', true );
            }
        }
    }
}
</script>